import React, { useState, useEffect, useRef } from "react";
import styles from "./ImageList.module.scss";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserSelectedProject } from "../../store/actions/projects";
import { getSelectorOptionsThunk } from "../../store/actions/selectorOptions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import {
  setCurrentImage,
  clearCurrentImage,
} from "../../store/reducers/images/imagesSlicer";
import { 
  clearPathologiesList,
  updatePathologiesList,
  updateRectanglesFromFreehandList,
  updateFreehandDrawingsList,
} from "../../store/actions/pathologies";
import { apiRoute } from "../../helpers";
import { setProjectSelectedAtLogin } from "../../store/reducers/projects/projectsSlicer";
import { updateDiagnosesList } from '../../store/actions/diagnoses';
import { updateTagsList } from '../../store/actions/tags';
// import socketio from './../../helpers/socket-client';

const ImageList = ({ imagesData, searchTerm, onStudyInstanceUIDChange }) => {

  const navigate = useNavigate();
  const currentImageSelected = useSelector((state) => state.images);

  const handleProjectSelect = async (projectId) => {
    await dispatch(setUserSelectedProject(projectId));
    await dispatch(getSelectorOptionsThunk(projectId));
    await dispatch(setProjectSelectedAtLogin(projectId));
    navigate(`/authorized/projects/${projectId}`);
  };

  const dispatch = useDispatch();
  const imagesDataRef = useRef(null);

  const [highlightImageId, setHighlightImageId] = useState(null);

  const previousImagesDataRef = useRef(null);

  const { projects } = useSelector((state) => state)
  const prev = useSelector((state) => state.previous.previous)
  const _selectionOptions = useSelector((state) => state.selectionOptions.options)

  const projectAtLoginData = useSelector(state => state.next.projectAtLogin);

  function isCurrentImage(id){
    if(currentImageSelected.currentImage) {
      return currentImageSelected.currentImage._id === id
    }
  }

  function getProjectNameForImage(image) {
    const imageProjectList = image.image.projects;
    if (Array.isArray(imageProjectList)) {
      const isProjectAssociated = imageProjectList.indexOf(image.pID) >= 0;
      if (isProjectAssociated) {
        const project = projects.projects.find(project => project._id === image.pID);
        return project ? project.name : 'Unknown Project';
      } else {
        return 'Unknown Project';
      }
    } else {
      console.error('imageProjectList is not an array', imageProjectList);
      return 'Unknown Project';
    }
  }

  const filteredImagesData = searchTerm
    ? imagesData.filter(image => {
        const projectName = getProjectNameForImage(image);
        return projectName.toLowerCase().includes(searchTerm.toLowerCase());
      })
    : imagesData;

  // useEffect(() => {

  //   if (imagesDataRef.current !== imagesData) {
  //     imagesDataRef.current = imagesData;
  //   }

  //   if (previousImagesDataRef.current !== imagesDataRef.current) {
  //     previousImagesDataRef.current = imagesDataRef.current;
  //     if (imagesDataRef.current.length > 0) {
  //       return setHighlightImageId(imagesDataRef.current[0]._id);
  //     }
  //   }
  //   return () => {
  //     previousImagesDataRef.current = imagesDataRef.current;
  //   };
  // }, [dispatch, imagesData]);

  const drawingFlagCheck = useSelector(
    (state) => state.selectionOptions.options.freehand_enabled
  );
  
  const [isFreeHand, setIsFreeHand] = useState(drawingFlagCheck);

  // const sendPropUpdate = (type, payload) => {
  //   if (payload && Array.isArray(payload) && payload.length > 0) {
  //     if (socketio.connected()) {
  //       console.log("Sending restore data", type, payload);
  //       socketio.broadcast({
  //         type,
  //         payload,
  //       });
  //     } else {
  //       console.error("Socket is not connected.");
  //     }
  //   } else {
  //     console.log(`Skipped sending ${type} because payload is empty or invalid`);
  //   }
  // };

  const handleImageClick = (image) => {
    if (!image) {
      dispatch(clearCurrentImage());
      setHighlightImageId(null);
      return;
    } else {
      const pastPath = prev.find(_prev => _prev.image._id === image.image._id);
      // console.log('checkerList', image.image)
      // console.log('prev', prev);
      // console.log('pastPath', pastPath.pathologies);
      let pathReload = [];
      let freehandReload = [];
      let rectanglesFromFreehandReload = [];
      let diagnosesArray = [];
      let tagsArray = [];
  
      if (pastPath && pastPath.pathologies) {
        diagnosesArray = pastPath.pathologies.diagnoses || [];
        tagsArray = pastPath.pathologies.tags || [];
  
        pastPath.pathologies.pathologies.forEach(_path => {
          const pathologyImageId = pastPath?.pathologies?.image;
          const _tmp_color = _selectionOptions.pathologies.find(i=> i.label === _path.name)
          const _color = _tmp_color ? _tmp_color.color : 'blue'
          console.log('is color', _color)
            if(isFreeHand) {
              freehandReload.push({
                id: _path?.coords.id,
                points: _path?.freehandpoints,
                text: _path?.name,
                imgId: pathologyImageId,
                color: _path?.color || _color || 'blue',
                stroke: _path?.color  || _color  || 'blue',
                strokeWidth: 2,
                fill: _path?.color || _color  || 'blue',
                opacity: 0.5,
                planes: _path?.planes,
                z: _path?.z,
                other: _path?.other,
                can3dSegment: _path?.can3dSegment,
                window: _path?.window,
                autoSegment: _path?.autoSegment,
                mapped_stats: _path?.mapped_stats,
                instanceId: _path?.instanceId
              });
              // sendPropUpdate('selectedLines', freehandReload);
              
              // Also include these pathologies as rectangles from freehand
              rectanglesFromFreehandReload.push({
                id: _path?.coords.id,
                x: _path?.coords.x,
                y: _path?.coords.y,
                width: _path?.coords.w,
                height: _path?.coords.h,
                color: _path?.color  || _color  || 'blue',
                text: _path?.name,
                imgId: pathologyImageId,
                planes: _path?.planes,
                z: _path?.z,
                other: _path?.other,
                can3dSegment: _path?.can3dSegment,
                window: _path?.window,
                autoSegment: _path?.autoSegment,
                mapped_stats: _path?.mapped_stats,
                instanceId: _path?.instanceId
              });
            } else {
                // Handle regular pathologies
                pathReload.push({
                  text: _path?.name,
                  x: _path?.coords.x,
                  y: _path?.coords.y,
                  width: _path?.coords.w,
                  height: _path?.coords.h,
                  color: _path?.color  || _color || 'blue',
                  id: _path?.coords.id,
                  imgId: pathologyImageId,
                  planes: _path?.planes,
                  z: _path?.z,
                  other: _path?.other,
                  can3dSegment: _path?.can3dSegment,
                  window: _path?.window,
                  autoSegment: _path?.autoSegment,
                  mapped_stats: _path?.mapped_stats,
                  instanceId: _path?.instanceId
                });
                // sendPropUpdate('selectedRectangles', pathReload);
            }
        });
      }
  
      dispatch(setCurrentImage(image.image));
      setHighlightImageId(image._id);

      const pl = {bb: pathReload, fh: freehandReload}
      onStudyInstanceUIDChange(image?.image?.dicom?.StudyInstanceUID, pl, image.image);

      dispatch(clearPathologiesList());
      dispatch(updatePathologiesList(pathReload));
      dispatch(updateFreehandDrawingsList(freehandReload));
      dispatch(updateRectanglesFromFreehandList(rectanglesFromFreehandReload));
      dispatch(updateDiagnosesList(diagnosesArray));
      dispatch(updateTagsList(tagsArray));
  
      handleProjectSelect(image.pID);
      // socketio.broadcast(socketio.hash(), image?.image?.dicom?.StudyInstanceUID, freehandReload);

      console.log('freehandReload', freehandReload);
      console.log('pathReload', pathReload);

      // sendPropUpdate('selectedLines', freehandReload);
      // sendPropUpdate('selectedRectangles', pathReload);
    }
  };
  
  return (
  <div className={styles["image-list-container"]}>
    {filteredImagesData.map((i, k) => (
      <div
        key={`${i.image._id}-${k}`}
        className={`${styles["image-list-item"]} ${
          isCurrentImage(i.image._id) ? styles["selected-image"] : ""
        } relative`}
        onClick={() => handleImageClick(i)}
      >
        
        
        <img
          src={`${apiRoute()}/image/${i.image._id}?thumb=yes`}
          alt=""
          className={styles["image-list-item-image"]}
        />
        <div>
          <h6 className={styles["image-list-item-title"]}>{i.image.image.name}</h6>
          <p className={styles["image-list-item-date"]}>
            Date : {moment(i.created_at).fromNow()}
          </p>
          <p className={styles["image-list-item-date"]}>
            {getProjectNameForImage(i)}
          </p>
        </div>

        {i.pathologies?.rejection === true && (
          <div className={styles["image-list-item-icon"]}>
          <FontAwesomeIcon 
            icon={faTriangleExclamation} 
            className="text-[#ff0000] w-4 h-4"
          />
        </div>
        )}
      </div>
      
    ))}
  </div>
);
};

export default ImageList;
