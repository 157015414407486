import React, { useEffect } from 'react'

const ViewerIframe = ({ src }) => {

  useEffect(() => {
    console.log('src', src)
  }, [src])

  return (
      <iframe
        className='div1'
        src={src}
        title="2D Viewer"
        width="100%"
        height="100%"
        allow="same-origin" 
        style={{ border: 'none', borderRadius: '19px', backgroundColor: '#14304c', overflow: 'hidden'}}
        allowFullScreen
        // style={{
        //   overflow: 'hidden' // Ensures no scrolling occurs
        // }}
      />
  );
};

export default ViewerIframe;