import { localhostRoute } from './localhost-route'

export function viewerRoute() {
  if (window.location.hostname === "localhost") {
    return localhostRoute().viewer;
  } else {
    return window.location.protocol + "//" + window.location.hostname + "/viewer";
  }
}
export function kViewerRoute() {
  if (window.location.hostname === "localhost") {
    return 'https://label.new.ratify.global/kviewer'
    // return localhostRoute().viewer;
  } else {
    return window.location.protocol + "//" + window.location.hostname + "/kviewer";
  }
}