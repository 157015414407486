import React, { useState, useEffect } from "react";
import Selector from "./Selector/Selector";
import Slider from "./Slider";
import NewWindow from "react-new-window";
import OhifViewerIframe from './OhifFrameViewer';
import ViewerIframe from './2D-Viewer';
import { useSelector, useDispatch } from "react-redux";
import { getImageCount } from "../store/actions/count";
import ImageList from "./ImageList/ImageList";
import { getNextImage } from "../store/actions/images/next";
import { getPreviousImages } from "../store/actions/images/previous";
import { getSelectedBoundingBox } from "../store/actions/pathologies";
import { setDisplayPathologiesCount } from "../store/actions/pathologiesCount";
import { clearCurrentImage } from "../store/actions/images/next";
import { viewerRoute, kViewerRoute } from './../helpers/viewer-route'
import { handleSliderToggles } from "../store/actions/slider";
import socketio from '../helpers/socket-client'
import { newWindow } from '../store/actions/window';
import BoundingBox from "./BoundingBox";
import {
  addNewPathologies,
  updatePathologiesList,
  addFreehandPathology,
  updateFreehandDrawingsList,
  addNewRectanglesFromFreehand
} from "../store/actions/pathologies";

const Main = () => {
  const dispatch = useDispatch();
  // console.log('acd')
  const { isLoggedIn, user } = useSelector(state => state.user);
  const projectId = useSelector((state) => state.projects.selectedProject);
  const [connected, setConnected] = useState(false)

  const [receivedData, setReceivedData] = useState([]);
  const [transformedData, setTransformedData] = useState([]);

  const nextImage = useSelector((state) => state.next.next);

  const currentImageSelected = useSelector((state) => state.images);
  const { open, locked } = useSelector(state => state.slider);

  const { projects } = useSelector((state) => state.projects);

  const project = projects.find((proj) => proj._id === projectId);
  const selectedImage = useSelector((state) => state.images?.currentImage);
  const previousImages = useSelector((state) => state.previous?.previous);
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [labelColor, setLabelColor] = useState();
  const [pathologyOption, setPathologyOption] = useState("");
  const [diagnosisOption, setDiagnosisOption] = useState("");
  const [bbText, setBBText] = useState([]);
  const [tagValue, setTagValue] = useState(false);
  const [enhancementValue, setEnhancementValue] = useState(false);
  const [hasId, setHasId] = useState(null);
  const [isEditable, setIsEditable] = useState(false);

  const [showBoundingBoxes, setShowBoundingBoxes] = useState(true);

  const [brightness, setBrightness] = useState(100);
  const [contrast, setContrast] = useState(100);
  const [saturate, setSaturate] = useState(100);
  const [sepia, setSepia] = useState(0);
  const [invert, setInvert] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");

  const [isPathologiesOpen, setIsPathologiesOpen] = useState(false);
  const [isDiagnosisOpen, setIsDiagnosisOpen] = useState(false);
  const [isTagsOpen, setIsTagsOpen] = useState(false);
  const [isEnhancementsOpen, setIsEnhancementsOpen] = useState(false);
  const [studyInstanceUID, setStudyInstanceUID] = useState();

  const token = localStorage.getItem("token");

  const { openWindow } = useSelector(state => state.window);

  const [resetSliderTrigger, setResetSliderTrigger] = useState(false);
  const [ohifViewerUrl, setOhifViewerUrl] = useState('http://localhost:3000');

  const projectAtLoginData = useSelector(
    (state) => state.projects.projectSelectedAtLogin
  );

  const drawingFlagCheck = useSelector(
    (state) => state.selectionOptions.options.freehand_enabled
  );

  const [isFreeHand, setIsFreeHand] = useState(drawingFlagCheck);

  const getSelectedProject = (state) => {
    return state.projects.projects.find(project => project._id === state.projects.selectedProject);
  }

  const projectVersion = useSelector(getSelectedProject);

  useEffect(()=> {
    if(isLoggedIn && user) {
      if (!socketio.connected()) {
        console.log('Not connected, connecting ...', user)
        socketio.setupConnection(user.email)
          .then((status) => {
            setConnected(status)
            if(status){
            const jwtToken = localStorage.getItem("token");
            socketio.selectProject(projectId)
            socketio.registerBroadcastReceiver(functionToCallOnBroadcast)
            socketio.sendJwt(jwtToken)
            }
          })
      }
    }
  }, []);

  // useEffect(() => {
  //   console.log('Connected status in Main.js', connected)
  //   if (connected) {
  //     const jwtToken = localStorage.getItem("token");
  //     socketio.sendJwt(jwtToken)
  //   }
  // }, [connected])

  // useEffect(() => {
  //   console.log('projectId1', projectId)
  //   if (connected && projectId) {
  //     console.log('projectId2', projectId)
  //     console.log(functionToCallOnBroadcast)
  //   }
  // }, [connected, projectId])

  const functionToCallOnBroadcast = function(data) {
    console.log('Received broadcast data label:', data);
    if (data.type === 'Close') {
      handleWindowClose();
    } else if (data.actionType === 'onMouseUp') {
      console.log('handleResetSlider');
      handleResetSlider();
    } else if (data.actionType === 'addNewPathologies') {
      console.log('Updating pathologies list:', data.payload);
      dispatch(addNewPathologies(data.payload));
    } else if (data.actionType === 'updatePathologiesList') {
      console.log('Updating pathologies list:', data.payload);
      dispatch(updatePathologiesList(data.payload));
    } else if (data.actionType === 'addFreehandPathology') {
      console.log('Adding freehand pathology:', data.payload);
      dispatch(addFreehandPathology(data.payload));
    } else if (data.actionType === 'addNewRectanglesFromFreehand') {
      console.log('Adding new rectangle from freehand:', data.payload);
      dispatch(addNewRectanglesFromFreehand(data.payload));
    } else if (data.actionType === 'updateFreehandDrawingsList') {
      console.log('Updating freehand drawings list:', data.payload);
      dispatch(updateFreehandDrawingsList(data.payload));
    } else {
      setReceivedData(data);
    }
  };
  
const selectedRectangles = useSelector(
  (state) => state.pathologies.pathologies
)

const selectedLines = useSelector(
  (state) => state.pathologies.freehandDrawings
)

const pathologyOptions = useSelector((state) => state.selectionOptions.options.pathologies);
const [sendKeys, setsendKeys] = useState({});
const sendPropUpdate = (type, payload) => {
  console.log('send', sendKeys, type)
  if (socketio.connected()) {
    if (Object.keys(sendKeys).includes(type))
    {
      sendKeys[type] += 1
    } else {
      sendKeys[type] = 0
    }
    setsendKeys(sendKeys)
    socketio.broadcast({
      type: type,
      payload: payload,
      key:  sendKeys[type]
    });
    console.log('Broadcast sent:', type, payload);
  } else {
    console.error("Socket is not connected.");
  }
};

useEffect(()=>{
  sendPropUpdate('drawingFlagCheck', drawingFlagCheck);
}, [drawingFlagCheck]);

useEffect(() => {
  sendPropUpdate('nextImage', { nextImage });
}, [nextImage]);

useEffect(() => {
  sendPropUpdate('selectedImage', { selectedImage });
}, [selectedImage]);

useEffect(() => {
  sendPropUpdate('selectedRectangles', { selectedRectangles });
}, [selectedRectangles]);

useEffect(() => {
  sendPropUpdate('selectedLines', { selectedLines });
}, [selectedLines]);

useEffect(() => {
    sendPropUpdate('pathologyOptions', { pathologyOptions });
}, [pathologyOptions]);

useEffect(() => {
  sendPropUpdate('updateLabelColor', { labelColor });
}, [labelColor]);
useEffect(() => {
    sendPropUpdate('updatePathologyOption', { pathologyOption });
}, [pathologyOption]);

useEffect(() => {
  sendPropUpdate('updateShowBoxes', { showBoxes: showBoundingBoxes });
}, [showBoundingBoxes]);

useEffect(() => {
  sendPropUpdate('updateEditId', { editId: hasId });
}, [hasId]);

useEffect(() => {
  console.log('editable')
  sendPropUpdate('updateEditState', { editState: isEditable });
}, [isEditable]);

// useEffect(() => {
//   // Ensure the payload is always an object with 'isFreeHand' property
//   const payload = { isFreeHand: drawingFlagCheck ? drawingFlagCheck : false };
//   console.log('isFreeHand is sending', payload.isFreeHand);
//   sendPropUpdate('isFreeHand1', payload);
// }, [drawingFlagCheck, isFreeHand]);

// useEffect(() => {
//   const payload = { isFreeHand: isFreeHand };
//   console.log('isFreeHand sent', payload.isFreeHand);
//   sendPropUpdate('isFreeHand2', payload);
// }, []);

// useEffect(() => {
//   const payload = { isFreeHand: !!drawingFlagCheck }; // Ensure it's always a boolean
//   console.log('Sending isFreeHand update:', payload.isFreeHand);
//   sendPropUpdate('isFreeHand', payload);
// }, [drawingFlagCheck]);

// useEffect(() => {
//   const payload = { isFreeHand: isFreeHand }; // Ensure it's always a boolean
//   console.log('Sending isFreeHand update:', payload.isFreeHand);
//   console.log('Sending isFreeHand state', isFreeHand);
//   sendPropUpdate('isFreeHand', payload);
// }, [isFreeHand]);

// // Sync `isFreeHand` value on mount
// useEffect(() => {
//   const payload = { isFreeHand: !!isFreeHand };
//   console.log('Sending initial isFreeHand:', payload.isFreeHand);
//   sendPropUpdate('isFreeHand', payload);
// }, []);

// Sync `isFreeHand` value on mount
// useEffect(() => {
//   const payload = { isFreeHand: !!isFreeHand };
//   console.log('Sending initial isFreeHand:', payload.isFreeHand);
//   sendPropUpdate('isFreeHand', payload);
// });

useEffect(() => {
  sendPropUpdate('updateImageSettings', {
    brightness,
    contrast,
    saturate,
    sepia,
    invert
  });
}, [brightness, contrast, saturate, sepia, invert]);

useEffect(() => {
  // Calculate total pathologies based only on selected rectangles
  const totalPathologies = selectedRectangles.length;

  // Dispatch the updated count
  dispatch(setDisplayPathologiesCount(totalPathologies));
}, [selectedRectangles, dispatch]);

useEffect(() => {
  // Calculate total pathologies based only on selected lines
  const totalPathologies = selectedLines.length;

  // Dispatch the updated count
  dispatch(setDisplayPathologiesCount(totalPathologies));
}, [selectedLines, dispatch]);


  const count = useSelector((state) => state?.count?.count);
  const images = useSelector((state) => state.previous.previous);
  
  const currentImage = useSelector((state) => {
    return state.next.next;
  });

  useEffect(() => {
    setIsFreeHand(drawingFlagCheck);

    console.log('drawingFlagCheck', drawingFlagCheck)
  }, [drawingFlagCheck]);

  const handleWindowClose = () => {
    const t_hash =  'restore'+socketio.hash()+selectedImage._id
    const pl = {bb: selectedRectangles, fh: selectedLines}
    socketio.setValue({ key: t_hash, value: pl })
    dispatch(newWindow(false));
  };

  const toggleOpenNewWindow = () => {

    const t_hash =  'restore'+socketio.hash()+selectedImage._id
    const pl = {bb: selectedRectangles, fh: selectedLines}
    socketio.setValue({ key: t_hash, value: pl })

    socketio.is_popped()
    dispatch(newWindow(true)); 
    if(open) {
      return 
    } else {
      dispatch(handleSliderToggles())
    }
  };

  useEffect(() => {
    let count = 0;
    let transformData = [];

    // console.log('currentUID', currentImageSelected?.currentImage?.dicom);
    // console.log('currentUID', currentImageSelected);
    receivedData?.value?.forEach((item) => {
      // console.log('item', item);
      // console.log('item.StudyInstanceUIDs', item?.StudyInstanceUIDs);
      // console.log('currentUID', currentImageSelected?.currentImage?.dicom?.StudyInstanceUID);

        if (item.StudyInstanceUIDs === currentImageSelected?.currentImage?.dicom?.StudyInstanceUID) {
            if (item?.pathologies) {
                count += item.pathologies.length;  // Increment count based on number of pathologies
                item.pathologies.forEach(p => {
                    transformData.push({  
                        text: p?.name,
                        points: p?.freehandpoints,
                        mapped_stats: p?.mapped_stats,
                        fill: "blue",
                        id: "i",
                        imgId: currentImageSelected.currentImage?._id,
                        opacity: 0.5,
                        stroke: "blue",
                        strokeWidth: 2,
                        planes: p?.planes,
                        z: p?.z,
                        other: p?.other,
                        can3dSegment: p?.can3dSegment,
                        window: p?.window,
                        autoSegment: p?.autoSegment,
                        instanceId: p?.instanceId
                    });
                });
            }
        }
    });

  setTransformedData(transformData) 
  dispatch(updateFreehandDrawingsList(transformData))  
  dispatch(setDisplayPathologiesCount(count));

  console.log('receivedData', receivedData)
}, [receivedData]);

  const handleEditId = async (value) => {
    await dispatch(getSelectedBoundingBox(value));
    setHasId(value);
  };

  useEffect(() => {
    const fetchData = async () => {
    };

    fetchData();
  }, [dispatch, projectId, token, projectAtLoginData]);

  const handleEditState = (value) => {
    setIsEditable(value);
  };

  useEffect(() => {
    const handleGetImageCount = async () => {
      setIsLoadingCount(true);
      try {
        await dispatch(getImageCount(token, projectId));
        setIsLoadingCount(false);
      } catch (error) {
        setIsLoadingCount(false);
      }
    };
    handleGetImageCount();
  }, [dispatch, projectId, token]);

  const getPathologyColor = (value) => {
    setLabelColor(value.color);
  };

  const getPathologyLabel = (value) => {
    setPathologyOption(value.label);
  };

  const getDiagnosisOptions = (value) => {
    setDiagnosisOption(value.value);
  };
  const getViewerParms = () => {
    const _pro =projectVersion
    console.log('image', selectedImage)
    const _di = selectedImage.dicom
    let pl = '&StudyInstanceUIDs='+_di.StudyInstanceUID
    if (_pro.extra && _pro.extra.grouping ) {
      if (_pro.extra.grouping === 'series'){
        pl += '&SeriesInstanceUIDs=' + _di.StudyInstanceUID.SeriesInstanceUID
      } 
      // else if (_pro.extra.grouping === 'instance'){
      //   pl += '&SeriesInstanceUIDs=' + 
      // }
    }
    return pl
    // 1.2.156.14702.1.1000.16.1.2020022319330635900020002
  }

  // const getTextOption = (value) => {
  //   setBBText(value);
  // };

  useEffect(() => {
    if(pathologyOption) {
      // console.log("pathologyOption", pathologyOption)
    }
  }, [pathologyOption])

  const handleDropdownChange = (pathOption) => {
    if (pathOption !== "initialStateOption") {
      setShowBoundingBoxes(false);
    }
  };

  useEffect(() => {
    if (currentImage) {
      const _currentImage = Object.assign({ pID: projectId }, currentImage);
      dispatch(getPreviousImages(_currentImage));
    }
  }, [currentImage, dispatch]);

  const UID = currentImage?.image?.dicom?.StudyInstanceUID

  useEffect(() => {
    if(UID) {
      setStudyInstanceUID(UID);
      // console.log('UID', UID);
    }
  }, [UID])

  const handleStudyInstanceUIDChange = (uid, pl, img) => {
    setStudyInstanceUID(uid);
    const t_hash =  'restore'+socketio.hash()+img._id
    socketio.setValue({ key: t_hash, value: pl })
    console.log('value to set hash', 'restore'+socketio.hash()+img._id)
    console.log('value to set', pl, selectedImage._id, img._id)
  };

  useEffect(() => {
    if(studyInstanceUID) {
      // console.log('studyInstanceUID', studyInstanceUID)
    }
  }, [studyInstanceUID])

  const handleResetSlider = () => {
    console.log('handleResetSlider')
    setResetSliderTrigger(prev => !prev); // Toggle to trigger useEffect in Slider
    setPathologyOption("");
    setShowBoundingBoxes(true);
  };

  const updateEnhancementValue = (value) => {
    setEnhancementValue(value);
  };

  return (
    <main role="main" className="main">
      {console.log(`http://localhost:3010/?hash=${socketio.hash()}&imgId=${selectedImage?._id}&freehand=${isFreeHand}`)}
      <div id="MainView">
        <nav className="navbar px-4 py-3 navbar-dark navbar-expand-lg">
          <li className="form-inline navy-fields">
            <form className="form-inline">
              <div role="group" className="input-group rounded-pill">
                <div className="input-group-prepend">
                  <div className="input-group-text navy-fields form-control prepend">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="search"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="svg-inline--fa fa-search fa-w-16"
                    >
                      <path
                        fill="currentColor"
                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                        class=""
                      ></path>
                    </svg>
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="__BVID__25"
                  placeholder="Search by project name..."
                  aria-label="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className={`input-group-append`}>
                  <div className="input-group-text navy-fields form-control append"></div>
                </div>
                <div style={{ color: "#0473b4", margin: "5px 5px 5px 15px" }}>
                  {projectId
                    ? `Project: ${project.name}`
                    : "no project selected"}
                  <span
                    className="badge ml-2 bg-yellow color-black font-weight-400 badge-secondary badge-pill"
                    title="images left to label in this project"
                  >
                    {count ? count : 0}
                  </span>
                </div>
              </div>
            </form>
          </li>

          <button
            type="button"
            aria-label="Toggle navigation"
            className="navbar-toggler collapsed"
            aria-expanded="false"
            aria-controls="nav-collapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {!openWindow && (
            <Slider
              hasId={hasId}
              getPathologyColor={getPathologyColor}
              getPathologyLabel={getPathologyLabel}
              getDiagnosisOptions={getDiagnosisOptions}
              enhancementValue={enhancementValue}
              bbText={bbText}
              labelColor={labelColor}
              handleEditId={handleEditId}
              handleEditState={handleEditState}
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              brightness={brightness}
              setBrightness={setBrightness}
              contrast={contrast}
              setContrast={setContrast}
              saturate={saturate}
              setSaturate={setSaturate}
              sepia={sepia}
              setSepia={setSepia}
              invert={invert}
              setInvert={setInvert}
              isPathologiesOpen={isPathologiesOpen}
              isDiagnosisOpen={isDiagnosisOpen}
              isTagsOpen={isTagsOpen}
              isEnhancementsOpen={isEnhancementsOpen}
              resetTrigger={resetSliderTrigger}
              onChange={handleDropdownChange}
              isFreeHand={isFreeHand}
            />
          )}
        </nav>
        <div className="px-4 inner-container">
          <div className="row inner-row">
            <div
              className={`div1 inner-col d-flex flex-column ${
                openWindow ? "col-7" : "col-3"
              }`}
            >
              <div
                role="tablist"
                className="accordion flex-column bg-dark-navy left-div"
              >
                <header role="tab" className="card-header p-0 flex-shrink-1">
                  <button
                    type="button"
                    className="btn btn-secondary btn-block"
                    aria-expanded="false"
                    aria-controls="accordion-1"
                    data-target="#accordion-1"
                  >
                    Current
                    {isLoadingCount ? (
                      <small
                        className="ml-1 spinner-border spinner-border-sm text-primary"
                        role="status"
                      ></small>
                    ) : (
                      <span className="badge ml-2 bg-yellow color-black font-weight-400 badge-secondary badge-pill">
                        {previousImages?.length ? previousImages?.length : 0}
                      </span>
                    )}
                  </button>
                </header>

                <div
                  className="flex-grow-1 overflow-y scroll-overflow image-h"
                  role="tabpanel"
                >
                  <div className="image-list-parent">
                    <ImageList
                      imagesData={images}
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                      onStudyInstanceUIDChange={handleStudyInstanceUIDChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            {openWindow && (
              <div className="col-5">
                <Slider
                  hasId={hasId}
                  enhancementValue={enhancementValue}
                  getPathologyColor={getPathologyColor}
                  getPathologyLabel={getPathologyLabel}
                  getDiagnosisOptions={getDiagnosisOptions}
                  bbText={bbText}
                  labelColor={labelColor}
                  handleEditId={handleEditId}
                  handleEditState={handleEditState}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                  brightness={brightness}
                  setBrightness={setBrightness}
                  contrast={contrast}
                  setContrast={setContrast}
                  saturate={saturate}
                  setSaturate={setSaturate}
                  sepia={sepia}
                  setSepia={setSepia}
                  invert={invert}
                  setInvert={setInvert}
                  resetTrigger={resetSliderTrigger}
                  onChange={handleDropdownChange}
                  isFreeHand={isFreeHand}
                />
                <NewWindow onUnload={handleWindowClose}>
                  <div>

                    {projectVersion.version === 1 ?
                      // (
                      //   <div className="card h-100 bg-dark-navy custom border-0">
                      //     <div className="card-header">
                      //       <div className="row">
                      //         <div className="col">
                      //           <h6 className="color-white">
                      //             {selectedImage ? selectedImage.image.name : ""}
                      //           </h6>
                      //         </div>
                      //         <div className="text-right col"></div>
                      //       </div>
                      //     </div>
                      //     <BoundingBox
                      //       labelColor={labelColor}
                      //       pathologyOption={pathologyOption}
                      //       onMouseUp={handleResetSlider}
                      //       showBoxes={showBoundingBoxes}
                      //       getTextOption={getTextOption}
                      //       editId={hasId}
                      //       editState={isEditable}
                      //       setIsEditable={setIsEditable}
                      //       handleNewEditState={handleNewEditState}
                      //       // imageStyle={imageStyle}
                      //       brightness={brightness}
                      //       contrast={contrast}
                      //       saturate={saturate}
                      //       sepia={sepia}
                      //       invert={invert}
                      //       isFreeHand={isFreeHand}
                      //     />
                      //   </div>
                      // ) 
                      (
                        <div 
                          style={{ width: '100%', height: '100vh' }} 
                        >
                        <ViewerIframe src={`${kViewerRoute()}/?hash=${socketio.hash()}&imgId=${selectedImage?._id}&freehand=${isFreeHand}`} openWindow={openWindow}/>
                        </div>
                      ) : (
                        <div 
                          style={{ width: '100%', height: '100vh' }} 
                        >
                          <OhifViewerIframe src={`${viewerRoute()}/viewer?hash=${socketio.hash()}${getViewerParms()}`} openWindow={openWindow}/>
                        </div>
                      )
                    }

                  </div>
                </NewWindow>
              </div>
            )}
            {!openWindow && (
              <div className={`div2 col-9`} 
                style={projectVersion.version === 1 ? { height: 'calc(100vh - 135px)' } : {}}
              >
                  <div 
                    style={projectVersion.version === 1 ? { width: '100%'} : { width: '100%', height: '100%'}} 
                  >
                    {projectVersion.version === 1 ? (
                      // <div className="card h-100 bg-dark-navy custom border-0">
                      //   <div className="card-header">
                      //     <div className="row">
                      //       <div className="col">
                      //         <h6 className="color-white">
                      //           {selectedImage ? selectedImage.image.name : ""}
                      //         </h6>
                      //       </div>
                      //       <div className="text-right col"></div>
                      //     </div>
                      //   </div>
                      //   <BoundingBox
                      //     labelColor={labelColor}
                      //     pathologyOption={pathologyOption}
                      //     onMouseUp={handleResetSlider}
                      //     showBoxes={showBoundingBoxes}
                      //     getTextOption={getTextOption}
                      //     editId={hasId}
                      //     editState={isEditable}
                      //     setIsEditable={setIsEditable}
                      //     handleNewEditState={handleNewEditState}
                      //     // imageStyle={imageStyle}
                      //     brightness={brightness}
                      //     contrast={contrast}
                      //     saturate={saturate}
                      //     sepia={sepia}
                      //     invert={invert}
                      //     isFreeHand={isFreeHand}
                      //   />
                      // </div>
                      <div 
                        style={{ width: '100%', height: '100vh'}} 
                      >
                        <ViewerIframe src={`${kViewerRoute()}/?hash=${socketio.hash()}&imgId=${selectedImage?._id}&freehand=${isFreeHand}`} openWindow={openWindow}/>
                      </div>
                      ) : (
                        <OhifViewerIframe src={`${viewerRoute()}/viewer?hash=${socketio.hash()}${getViewerParms()}`} openWindow={openWindow}/>
                    )}
                  </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Selector
        toggleOpenNewWindow={toggleOpenNewWindow}
        updateEnhancementValue={updateEnhancementValue}
      />
    </main>
  );
};

export default Main;
